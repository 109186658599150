import React from 'react';
//import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const LiveStreamingLandingPage = () => (
  <Layout>
    <SEO title="Live Streaming" />
    <h1>Keep checking back.</h1>
    <p>RigaBee will be launching soon!</p>
  </Layout>
);

export default LiveStreamingLandingPage;
